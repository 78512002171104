// src/components/CODLesson.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CODExercise from './CODExercise';
import Form from './Form';

const CODLesson = () => {

    useEffect(() => {
        document.title = "C.O.D: Complément d'Objet Direct";
    }, []);


    const [lessonUnderstood, setLessonUnderstood] = useState(false);
    const [attentionUnderstood, setAttentionUnderstood] = useState(false);

    const isReadyForExercise = lessonUnderstood && attentionUnderstood;
    return (
        <div>
        
         
                <h1>C.O.D: Complément d'Objet Direct</h1>
                <h2>Leçon et explications</h2>
                <p>
                    Le complément d'objet direct est un mot ou (groupe de mots) qui se joint au verbe SANS PREPOSITION pour en compléter le sens.
                    Il subit l'action accomplie par le sujet.
                </p>

                <ul>
                    <li>Il appartient au groupe verbal.</li>
                    <li>C'est un complément essentiel, on ne peut pas le supprimer car la phrase n'aurait plus de sens.</li>
                    <li>Le C.O.D est toujours précédé d'un verbe transitif direct.</li>
                    <li>Le C.O.D est le mot qui devient sujet lorsqu'on tourne la phrase à la forme passive.</li>
                </ul>

                <h3>Exemple:</h3>
                <p>    Pierre mange UNE POMME.
                    UNE POMME est mangée par Pierre.
                </p>




                <h2>Comment trouver le complément</h2>
                <p>
                    Pour trouver le C.O.D, il vous suffit de poser la question "QUOI ?" ou "QUI ?"
                </p>

                <h3>Exemple:</h3>
                <p>   Tu conduis une voiture. ➜ Tu conduis quoi ? une voiture.
                </p>
                <p>
                    Le C.O.D est remplaçable par un pronom comme "le", "la", "les", "que"...
                </p>

                <h3>Exemple:</h3>
                <p>  Tu manges la POMME. ➜ Tu LA manges.
                </p>
                <p>
                    "LA" est le pronom qui remplace la pomme, donc "LA" est C.O.D.
                </p>

                <h2>Nature du C.O.D :</h2>
                <p>Le C.O.D peut être de différentes natures. Ci-dessous, une liste accompagnée d'un exemple pour chacune d'entre elles :</p>

                <h3>Un groupe nominal</h3>
                <p>Exemple : Tu conduis la VOITURE. ➜ VOITURE</p>

                <h3>Un pronom</h3>
                <p>Exemple : Tu LA conduis. ➜ LA</p>

                <h3>Un verbe à l'infinitif</h3>
                <p>Exemple : Tu aimes CONDUIRE. ➜ CONDUIRE</p>

                <h3>Une proposition </h3>
                <p>Exemple : Il m'a dit QUE TU ADORAIS CONDUIRE. ➜ QUE TU ADORAIS CONDUIRE.</p>


                <fieldset className='center'>
                    <legend>Avez-vous compris la leçon ?</legend>
                    <label>
                        <input
                            type="radio"
                            value="Oui"
                            checked={lessonUnderstood}
                            onChange={() => setLessonUnderstood(true)}
                        />
                        Oui
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="Non"
                            checked={!lessonUnderstood}
                            onChange={() => setLessonUnderstood(false)}
                        />
                        Non
                    </label>
                </fieldset>



                <h2>Point d’attention</h2>
                <p> Ne pas confondre C.O.D et Attribut du sujet !<br />
                    Tu es COSTAUD ! ➜ "costaud" est attribut du sujet car le C.O.D est toujours différent du sujet.<br />
                    Les attributs du sujet sont introduits par des verbes d'états comme sembler, paraître, être...
                </p>

                <fieldset className='center'>
                    <legend>Avez-vous compris le point d’attention ?</legend>
                    <label>
                        <input
                            type="radio"
                            value="Oui"
                            checked={attentionUnderstood}
                            onChange={() => setAttentionUnderstood(true)}
                        />
                        Oui
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="Non"
                            checked={!attentionUnderstood}
                            onChange={() => setAttentionUnderstood(false)}
                        />
                        Non
                    </label>
                </fieldset>

                <h2>Voir la leçon en vidéo sur YouTube (vidéo proposée par la plateforme École Au Sénégal) </h2>
                
                <p><a href='https://www.youtube.com/watch?v=Sv4P_sbczGo' target='blank'>Cours - CM1 - Français : Grammaire - Le Complément d'Objet Direct (C.O.D)</a></p>

                <h2>Exercice</h2>
                {isReadyForExercise ? (
                    <CODExercise />
                ) : (
                    <p>Veuillez confirmer la compréhension de la leçon et du point d'attention avant de pouvoir passer à l'exercice.</p>
                )}

                <Form />
          
        </div>

    );
};

export default CODLesson;